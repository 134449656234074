.#{$rt-namespace}__form {
  --podium-cds-typography-body1: theme(fontFamily.roobert);
  font-weight: 500;

  /* input */
  --podium-cds-color-text-secondary: theme(colors.gray.400);
  --podium-cds-color-border-primary: theme(colors.gray.400);

  /* input:active*/
  --podium-cds-color-text-primary: theme(colors.gray.300);
  --podium-cds-color-border-active: theme(colors.gray.200);

  /* input:disabled */
  --podium-cds-color-border-disabled: theme(colors.gray.600);
  --podium-cds-color-text-disabled: theme(colors.gray.600);

  .nds-input-label.active {
    --podium-cds-color-text-primary: theme(colors.gray.500);
  }
  input:-webkit-autofill {
    --color-text-primary: theme(colors.gray.300);
  }
  .nds-input-support-text {
    font-size: 0.75rem;
  }
}
