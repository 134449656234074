.#{$rt-namespace}__close-button {
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;

  transition: 0.3s ease;
  align-self: center;

  & > svg {
    fill: currentColor;
    height: 11px;
    width: 11px;
    stroke-width: 1;
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
}
