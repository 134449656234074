.#{$rt-namespace}__toast {
  &-theme--dark {
    background: var(--toastify-color-dark);
    color: var(--toastify-text-color-dark);
    @apply border border-gray-500;
  }
  &-theme--dark#{&}--success {
    .#{$rt-namespace}__toast-icon svg {
      @apply text-green-500;
    }
    #close-button {
      @apply hover:text-gray-950 text-white;
    }
  }
  &-theme--light#{&}--success {
    .#{$rt-namespace}__toast-icon svg {
      @apply stroke-gray-950 fill-green-500;
    }
    #close-button {
      @apply text-gray-950;
    }
  }

  &-theme--light#{&}--error {
    background: var(--toastify-color-error);
    color: var(--toastify-text-color-light);
    @apply border border-red-600;
    .#{$rt-namespace}__toast-icon svg {
      @apply text-red-600;
    }
    #close-button {
      @apply text-gray-950;
    }
  }

  &-theme--dark#{&}--error {
    background: var(--toastify-color-dark);
    color: var(--toastify-text-color-dark);
    @apply border border-gray-500;

    .#{$rt-namespace}__toast-icon svg {
      @apply fill-red-450  text-gray-700;
    }
    #close-button {
      @apply hover:text-gray-950 text-white;
    }
  }

  &-theme--light {
    background: var(--toastify-color-light);
    color: var(--toastify-text-color-light);
    @apply border border-white;
  }

  // we're not using these right now
  &-theme--colored#{&}--default {
    background: var(--toastify-color-light);
    color: var(--toastify-text-color-light);
  }
  &-theme--colored#{&}--info {
    color: var(--toastify-text-color-info);
    background: var(--toastify-color-info);
  }
  &-theme--colored#{&}--success {
    color: var(--toastify-text-color-success);
    background: var(--toastify-color-success);
  }
  &-theme--colored#{&}--warning {
    color: var(--toastify-text-color-warning);
    background: var(--toastify-color-warning);
  }
  &-theme--colored#{&}--error {
    color: var(--toastify-text-color-error);
    background: var(--toastify-color-error);
    border: #ee0005 1px solid;
  }
}

.#{$rt-namespace}__progress-bar {
  &-theme--light {
    background: var(--toastify-color-progress-light);
  }
  &-theme--dark {
    background: var(--toastify-color-progress-dark);
  }
  &--info {
    background: var(--toastify-color-progress-info);
  }
  &--success {
    background: var(--toastify-color-progress-success);
  }
  &--warning {
    background: var(--toastify-color-progress-warning);
  }
  &--error {
    background: var(--toastify-color-progress-error);
  }
  &-theme--colored#{&}--info,
  &-theme--colored#{&}--success,
  &-theme--colored#{&}--warning,
  &-theme--colored#{&}--error {
    background: var(--toastify-color-transparent);
  }
}
