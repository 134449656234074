$rt-namespace: "Toastify";
$rt-mobile: "only screen and (max-width : 480px)" !default;

.toastThemeColors {
  // background color of toasts
  --toastify-color-light: theme(colors.white);
  --toastify-color-dark: theme(colors.gray.700);
  --toastify-color-info: theme(colors.blue.700); // currently not being used
  --toastify-color-success: theme(colors.green.50); // currently not being used
  --toastify-color-warning: theme(colors.red.300); // currently not being used
  --toastify-color-error: theme(colors.red.50);
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  // currently not being used
  --toastify-color-error: theme(colors.red.50);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 420px;
  --toastify-toast-min-height: 72px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: var(--font-roobert);
  --toastify-z-index: 9999;

  --toastify-text-color-light: theme(colors.gray.950);
  --toastify-text-color-dark: theme(colors.white);

  //Used only for colored theme - we're not using these right now
  --toastify-text-color-info: theme(colors.gray.950);
  --toastify-text-color-success: theme(colors.white);
  --toastify-text-color-error: theme(colors.red.600);

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided - not used right now
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
